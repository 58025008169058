// IMPORTS
@import (reference) "../settings.less";

// MAIN PAGE PROMO ROW
.main-page-promo-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: var(--rowGap) 0;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        background-color: @light-bg;
        z-index: -1;
    }

    .product-hover > p {
        -webkit-line-clamp: 4;
        max-height: ~"calc(4 * (var(--fontSize) * var(--lineHeight)))";
    }
}

.slider-with-products.slider2 {
    background-color: white;
    width: ~"calc((100% - 36px) / 2)";
    margin: 0;
    padding: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    border-radius: var(--borderRadius);

    .headline {
        margin-bottom: 0;
    }

    a.main-page-product-all {
        margin-top: 0;
        margin-left: auto;
    }

    .swiper {
        width: 100%;
    }

    .swiper-buttons-wrapper {
        display: flex;
        gap: 5px;

        * {
            margin: 0;
        }
    }
}

.product-single-promoted {

    > div {
        display: flex;
        align-items: center;
        gap: 50px;
    }

    figure {
        height: 232px;
        aspect-ratio: 1;
        flex-shrink: 0;
        position: relative;
    }

    .product-name {
        font-weight: 600;
        font-size: 16px;
    }

    p {
        color: @font-color;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        max-height: ~"calc(4 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product-price {
        height: auto;
    }

    .product-badges {
        left: 0;
        top: 0;
        width: 100%;
    }
}

.product-single-promoted-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

@media (min-width: 1280px) {
    .slider-with-products.slider2 {

    }
}

.goshop-projects {
    background-color: @main-color;
    width: ~"calc((100% - 36px) / 2)";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    border-radius: var(--borderRadius);

    em {
        font-style: normal;
        color: white;
        font-size: 24px;
        margin-bottom: 36px;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

// NEWS BANNER
.news-banner {
    position: relative;
    padding: var(--rowGap) 0 0;

    &:before {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 1px;
        background-color: @border-color;
    }

    .headline {
        clear: none;
        margin-bottom: 18px;
    }

    a.main-page-product-all {
        position: absolute;
        top: var(--rowGap);
        right: 0;
        margin-top: 0;
    }
}

.news-banner-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.news-article {
    width: ~"calc((100% - 36px) / 2)";
    margin: 0 36px 0 0;
    position: relative;
    min-height: 224px;
    padding-left: 369px;
    display: flex;
    flex-direction: column;
    padding-top: 13px;

    &:nth-child(2n) {
        margin-right: 0;
    }

    figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 333px;
        height: 224px;
        transform-origin: left center;
        transition: transform 0.2s ease-in-out;
        border-radius: var(--borderRadius);
        overflow: hidden;

        &:hover {
            transform: translateX(2px) scale(1.04);
        }
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }

    b {
        color: @header-color;
        font-weight: 600;
        font-size: 18px;
        display: block;

        &:hover {
            text-decoration: underline;
        }
    }

    p {
        color: @font-color;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        max-height: 96px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 11px;
    }

    i {
        font-style: normal;
        font-weight: 600;
        color: @header-color;
        display: inline-block;
        position: relative;
        margin-top: 18px;

        &:after {
            content: '';
            position: absolute;
            bottom: 1px;
            left: 0;
            height: 6px;
            width: 110px;
            background-color: @main-color;
            z-index: -1;
            transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
            transform-origin: left center;
        }

        &:hover:after {
            background-color: #C5C5C5;
            transform: scaleX(1.1);
        }
    }
}